import React, { useRef } from 'react';
import classNames from 'classnames/bind';
import { P } from 'koba/components/Typography';
import { useTranslation } from 'react-i18next';
import PubSub from 'pubsub-js';

import UserAvatar from 'components/UserAvatar';
import { useCommunityContext } from 'components/CommunityContext';
import ModeratorBadge from 'components/ModeratorBadge';
import { eventNames } from 'constants/eventNames';

import { UserFieldsFragment as PostListUser } from '__generated__/graphql/legacy/graphql';
import { hasModeratorBadge } from 'utils/userHelpers';

import TrackingEvent from 'constants/trackingEvents';
import styles from './UserDetail.module.scss';

const cx = classNames.bind(styles);

interface UserDetailProps {
  author?: PostListUser | null;
  editedAt?: string;
  isEditing?: boolean;
  isFollowing?: boolean;
  isReply?: boolean;
  time: string;
  totalReplyCount?: number;
}

export const UserDetail: React.FC<UserDetailProps> = ({
  author,
  editedAt,
  time,
  isEditing,
  isReply,
  totalReplyCount = 0,
  isFollowing,
}) => {
  if (!author) return null;

  const userDetailRef = useRef(null);
  const { t } = useTranslation();
  const { trackEvent } = useCommunityContext();
  const userHasModeratorBadge = hasModeratorBadge(author);

  const profileAriaLabel = t(
    'components-userDetail-posted_by_aria_label',
    `Posted by {{fullName}} {{moderator}} {{time}}. Press enter or space to open profile panel`,
    {
      fullName: author.fullName,
      time,
      moderator: userHasModeratorBadge ? t('Moderator') : '',
    }
  );

  const handleUserDetailAction = (event) => {
    if (event.key === 'Enter' || event.key === ' ' || event.type === 'click') {
      event.preventDefault();
      event.stopPropagation();
      PubSub.publish(eventNames.OPEN_PROFILE_PANEL, {
        userId: author.id,
        lastElementRef: userDetailRef,
      });

      trackEvent(TrackingEvent.PROFILE_PANEL_TAB_OPENED, {});
    }
  };

  const extraProps = {
    role: 'button',
    'aria-label': profileAriaLabel,
    tabIndex: 0,
    onClick: handleUserDetailAction,
    onKeyDown: handleUserDetailAction,
    ref: userDetailRef,
  };

  return (
    <div
      className={cx('user-detail', {
        'user-detail__edit': isEditing,
        'user-detail__reply': isReply,
      })}
      data-qa="user-detail"
      {...extraProps}
    >
      <div
        className={cx('user-detail__wrapper')}
        data-qa="user-detail__wrapper"
      >
        <UserAvatar
          avatarUrl={author.avatarUrl}
          className={cx('user-detail__avatar')}
          size="sm"
          srcSet={`${author.avatarUrl}?width=40 1x, ${author.avatarUrl}?width=40&dpr=2 2x, ${author.avatarUrl}?width=40&dpr=3 3x`}
          userId={author.id}
          username={author.fullName || ''}
        />
      </div>

      {!isEditing && (
        <div>
          <div className={cx('user-detail__name-moderator')}>
            <P
              className={cx('user-detail__name')}
              data-qa="main-user-detail-name__link"
              title={author.fullName || ''}
            >
              {author.fullName}
            </P>

            {userHasModeratorBadge && (
              <ModeratorBadge className={cx('user-detail__moderator-badge')}>
                {t('common-Moderator', 'Moderator')}
              </ModeratorBadge>
            )}
          </div>

          <P className={cx('user-detail__label')}>
            <span className={cx('user-detail__time')}>{time}</span>
            {editedAt && (
              <span className={cx('user-detail__edited')}>{t('Edited')}</span>
            )}
            {totalReplyCount > 0 && (
              <span className={cx('user-detail__total-replies')}>
                {t('{{count}} reply', { count: totalReplyCount })}
              </span>
            )}
            {!isReply && isFollowing && (
              <span className={cx('user-detail__following')}>
                {t('Following')}
              </span>
            )}
          </P>
        </div>
      )}
    </div>
  );
};

export default UserDetail;
